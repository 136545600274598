.step {
  display: flex;
  align-items: center;
  justify-content: center;
  top:0;
  left:0;
  width:100%;
  height: 100%;
  position: relative;
  overflow:hidden;
}

.step .copy-block {
  width:30vw;
  left:15vw;
  position: absolute;
  text-align: left;
}

.step .copy-block h1 {
  font-size: 4.7vw;
  line-height: 4.7vw;
}

.step .copy-block h2 {
  margin-top:.5vw;
}

.step .copy-block h3 {
  margin-top:3vw;
  font-size: 1.2vw ;
}
.step .copy-block p {
  margin-top:2vw;
}

.step .title-icon{
  width:3vw;
  margin-left:-.5vw;
}

.step-4 .copy-block p {
  font-size:1.7vw;
  margin-top:1vw;
}

.steps-videos {
  left:50%;
  z-index: 10;
  width:50vw;
  height: 56.25vw;
  position: fixed;
}

.steps-videos video{
  width:100%;
  position: absolute;
  left:0;
  top:0;
  visibility: hidden;
}

.step-1 {
  z-index:10;
}


.step-2 {
  z-index:9;
}


.step-3 {
  z-index:8;
}


.step-4 {
  z-index:7;
}


.step-5 {
  z-index:6;
}

.share-links {
  margin-top:2vw;
  text-align: left;
  display: flex;
  gap:1vw;
  justify-content: left;
}


.share-links .share-link {
  width:6.7vw;
  height: auto;
  cursor: pointer;
}


.share-links .share-link:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}


@media (orientation: portrait) {
  .step .desktop { 
    display: none;
  }
  .step .copy-block {
    width:80vw;
    left:8.5vw;
    position: absolute;
    text-align: left;
    top:60%;
    z-index:10;
  }
  
  .step .copy-block h1 {
    font-size: 9vw;
    line-height: 9vw;
  }
  
  .step .copy-block h2,   .step .copy-block h2 a {
    margin-top:2vw;
    font-size: 5.6vw;
  }
  
  .step .copy-block h3 {
    margin-top:4vw;
    font-size:3.7vw;
  }
  .step .copy-block p, .step .copy-block a {
    margin-top:1vw;
    margin-top:3vw;
    font-size:3.7vw;
  }
  
  .step .title-icon{
    width:6.5vw;
  }
  
  .steps-videos {

    left:10vw;
    z-index: 1;
    width:80vw;
    height: 160vw;
    position: fixed;
  }
  
  .steps-videos video{
    width:100%;
    position: absolute;
    left:0;
    top:0;
    visibility: hidden;
    outline: none;
    -webkit-mask-image: -webkit-radial-gradient(white, black);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
  }
  
  .step-1 {
    z-index:10;
  }
  
  
  .step-2 {
    z-index:9;
  }
  
  
  .step-3 {
    z-index:8;
  }
  
  
  .step-4 {
    z-index:7;
  }
  
  
  .step-5 {
    z-index:6;
  }
  
  .share-links {
    align-items: flex-start;
    flex-direction: row;
    gap:1vw;
    margin-top:5vw;
  } 
  
  
  .share-links .share-link {
    margin-right:2vw;
    width:20vw;
  }
  
  
  .share-links .share-link:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }
  

}
.end {
  height: 100vh;
  background:#FFD9DF;
  position: relative;
  z-index: 10;
  display: flex;
	flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  overflow:hidden;
}

.end .copy-block {
  width:30vw;
	margin-left:5vw;	
	text-align: left;
	z-index:1;
}


.end .copy-block h1 {
  font-size:4.7vw;
  line-height:4.7vw;
  width:25vw;
}

.end .copy-block h2 {
  margin-top:1vw;
}

.end .copy-block p {
  margin-top:2vw;
}

.end .title-icon{
  width:5vw;
  margin-bottom:1vw;
}

.end .media {
  width:40vw;
	margin-right:2.5vw;
  
}


@media (orientation: portrait) {

  .end{
    flex-direction: column;
  }
  
  .end .copy-block {
    width:85vw;
    margin-left:2.5vw;
		margin-top:5vw;
		text-align: left;
		z-index:1;
  }
  
  
  .end .copy-block h1 {
    font-size:9.3vw;
		line-height: 9.3vw;   
		letter-spacing: 0.025em;
    width:100%;
  }
  
  .end .copy-block h2 {
    margin-top:1vw;
  }
  
  .end .copy-block p {
    margin-top:5vw;
  }

  .end .copy-block p, .end .copy-block p a {
    font-size:3.7vw;
		line-height:5.8vw;  
  }
  
  .end .title-icon{
    width:14vw;
	  margin-bottom:1vw;
  }
  
  .end .media {
    width:65vw;
    margin-top:5vw;
    margin-right:0;
  }



}
.side-bar {
  position: fixed;
  z-index: 100;
  top:50%;
  left:2.5vw;
  transform: translate(0,-50%);
  display: flex;
  flex-direction: column;
}

.side-bar-button{
  width: 1.0vw;
  height:1.0vw;
  min-width:12px;
  min-height:12px;
  max-width:24px;
  max-height:24px;
  position: relative;
  cursor: pointer;
}

.side-bar-button-outline{
  position: absolute;
  width:100%;
}

.side-bar-button-fill{
  position: absolute;
  width:100%;
  opacity:0;
  visibility: hidden;
}

@media (orientation: portrait) {
  .side-bar { 
    display: none;
  }
}
.intro {
  position: fixed;
  width:100%;
  height: 100%;
  left:0;
  top:0;
  z-index:200;
  background:white;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;
}

.intro-splash{
  position: absolute;
  width:100%;
  height: 100%;
  left:0;
  top:0;
  background:white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.intro-image{
  position: absolute;
  width:100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  left:0;
  top:0;
  
}

.intro-screen{
  position: absolute;
  width:100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
  left:0;
  top:0;
  
}

.intro-play-icon{
  position: absolute;
  z-index: 100;
  left:-100%;
  top:-100%;
  width:8vw;
}


.intro-video{
  position: absolute;
  width:100%;
  height: 100%;
  left:0;
  top:0;
  background:white;
}

.intro-video button {
  background:none;
  border:none;
  position: absolute;
  cursor: pointer;
  z-index: 10;
  opacity: 0;;
}

.intro-video button img {
  width:7vw;
}

.intro-sound-button {
  bottom:1vh; left: 2vw;
}

.intro-sound-button:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.intro-skip-button {
  bottom:1vh; left: 10vw;
}

.intro-skip-button:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.intro .player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
  transform: translateY(-50%);
  top:50%;
 
}

.intro .react-player {
  position: absolute;
  top: 0;
  left: 0;
}

video {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}







@media (orientation: portrait) {

  .intro-play-icon{
    position: absolute;
    z-index: 100;
    left:43% !important;
    top:43% !important;
    width:12vw;
  }
  
  .intro-sound-button {
    bottom:1vh; left: 2vw;
  }
  
  .intro-skip-button {
    bottom:1vh; left: 10vw;
  }

  .intro-video button img {
    width:15vw;
  }

  .intro-sound-button {
    bottom:10vh; left: 20vw;
  }
  
  .intro-skip-button {
    bottom:10vh; left: 60vw;
  }

  .intro-image{

    object-fit: contain;

  }
  
  .intro-screen{
 
    object-fit: contain;

    
  }

}
body,html {
  margin: 0; padding:0;
  
}

* {
  font-variant-ligatures: none;
  }

iframe { outline: none;}
video { outline: none;}


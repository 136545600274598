.footer {
  background:white;
  width:100%;
  padding-bottom: 30px;
  left:0;
  width:100%;
  z-index: 10;
  position: relative;
}



.footer .footer-info {

  left:5vw;
  top:0vw;
  position: relative;
  text-align: left;
  display: flex;
  gap:20px;
  
}

.footer .footer-info p {
  font-size: .72vw;
  font-family: forma-djr-micro, sans-serif; 
  font-style: normal;
  font-weight: 300;
  margin-top:.5vw;

}

.footer .footer-info .lolLogo{
  width:5.5vw;
  justify-content: center;
}

.footer .footer-info .lolLogo:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}



.footer .footer-social{
  right:5vw;
  position: absolute;
  top:0vw;
}

.footer .footer-social h4 {
  top: 0;
  left: 0;
  text-align: left;

}

.footer .footer-social-links{
  text-align: left;
  display: flex;
  gap:20px;
  justify-content: center;
  margin-left:-1vw
}



.footer .footer-social img{
  width:3.645vw;
}

.footer .footer-social img:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}


@media (orientation: portrait) { 

  .footer {
    background:white;
    width:100%;
    position: relative;
    left:0;
    z-index: 10;
  }
  
  
  
  .footer .footer-info {
  
    left:3vw;
    position: relative;
    text-align: left;
    display: flex;
    gap:5vw;
    flex-direction: column;
    
  }
  
  .footer .footer-info p {
    font-size: 3.4vw;
    line-height: 4.8vw;;
    font-family: forma-djr-micro, sans-serif; 
    font-style: normal;
    font-weight: 300;
  
  }
  
  .footer .footer-info .lolLogo{
    width:17vw;
    justify-content: center;
  }
  
  
  
  
  .footer .footer-social{
    left:3vw;
    position: relative;
    margin-top:5vw;
    padding-bottom:0vh;
  }
  
  .footer h4 {
    top: 0;
    left: 0;
    text-align: left;
    font-size: 3.7vw;
  }

  .footer p {
    font-size: 3.7wv;
  }

  
  .footer .footer-social-links{
    text-align: left;
    display: flex;
    justify-content: left;
    gap:2vw;
    margin-left: -4vw;
  }
  
  
  
  .footer .footer-social img{
    width:16vw;
    padding:0; margin: 0;
  }
  
  .footer .footer-social img:hover {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
  }
}
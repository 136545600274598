.app .gallery {
  height: auto;
  background:white;
  z-index: 10;
  width:100%;
  height: unset;
  min-height: 100%;
}

.gallery .copy-block {
  width:90vw;
  left:50%;
  top:15vh;
  transform: translate(-50%,0);
  position: absolute;
  text-align: center;
  z-index:1;
}


.gallery .copy-block h1 {
  font-size: 4.7vw;
  line-height: 4.8vw;;
}


.gallery .media {
  width:50%;
  position: absolute;
  left:0%;
  top:50%;
  transform: translate(0,-50%);
}

.gallery .bg {
  width:100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left:0;
  top:0;
  z-index:0;
}

.gallery .posts { 
  margin-top:10vh;
  margin-left:10vw;
  margin-right:10vw;
  margin-bottom:15vh;
}

.gallery .post {
  width: 25%;
  background:lightblue;
  height:20vw;
  
}


.flockler-grid-items a.flockler-btn-load-more:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.gallery .copy-block .landscape {
  display: block;
}

.gallery .copy-block .portrait  {
  display: none;
}


@media (orientation: portrait) {
  .app .gallery {
    height: auto;
    background:white;
    z-index: 10;
    width:100%;
    height: unset;
    min-height: 100%;
  }
  
  .gallery .copy-block {
    width:90vw;
    left:50%;
    top:15vh;
    transform: translate(-50%,0);
    position: absolute;
    text-align: center;
    z-index:1;
  }
  
  
  .gallery .copy-block h1 {
    font-size: 7.5vw;
    line-height: 7.3vw;
    width: 85vw;
    margin-left: 2.5vw;
    
  }
  
  .gallery .copy-block .landscape {
    display: none;
  }
  
  .gallery .copy-block .portrait  {
    display: block;
  }

  .gallery .posts { 
    margin-top:10vh;
    margin-left:2.5vw;
    margin-right:2.5vw;
    margin-bottom:5vh;
  }
  
  
}
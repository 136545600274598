.landing {
  min-height: 100%;
  background:white;
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow:hidden;

}

.landing .copy-block {
  width:30vw;
  left:15vw;
  z-index: 10;
  position: absolute;
  text-align: left;
}

.landing .copy-block h2 {
  margin-top:1vw;
}

.landing .copy-block p {
  margin-top:3vw;
}

.landing .media {
  width:50%;
  position: absolute;
  left: 40%;
   width: 100%;
   visibility: hidden;
}





@media (orientation: portrait) {
  
  .landing .copy-block {
    width:85vw;
    left:7.5vw;
    z-index: 10;
    position: absolute;
    text-align: left;
    top:15vh;
  }
  
  .landing .copy-block h1 {
    font-size:18vw;
    line-height: 14vw;   
    letter-spacing: 0.025em;
  }

  .landing .copy-block h2, .landing .copy-block h2 a {
    margin-top:1vw;
    font-size:8vw;
    line-height: 10vw;   
    letter-spacing: 0.025em;
  }
  
  .landing .copy-block p {
    font-size:3.7vw;
		line-height:5.8vw;    
  }
  
  .landing .media {
    position: relative;
    top:45vw;
    left: 30%;
    width: 200%;
  }


}
.about {
	height: 100vh;
	width:100%;
	background:#FFD9DF;
	position: relative;
	z-index: 10;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: center;
	overflow:hidden;
}

.about .hearts {
	position: absolute;
	top:0;
	left:0;
	width:100vw;
	height: 100vh;
	z-index:0;
}

.about .copy-block {
	width:30vw;
	margin-top:2vw;
	margin-left:5vw;	
	text-align: left;
	z-index:1;
}

.about .copy-block h1 {
	font-size: 5.8vw;
	line-height: 5.8vw;
  }

.about .copy-block h2 {
  margin-top:1vw;
}

.about .copy-block p {
  margin-top:2vw;
}

.about .title-icon{
  width:5vw;
  margin-bottom:1vw;
}

.about .media {

	width:40vw;
	margin-right:2.5vw;
}




@media (orientation: portrait) {

	.about {
		flex-direction: column;
	}
	
	.about .copy-block {
		width:85vw;
    	margin-left:2.5vw;
		margin-top:5vw;
		text-align: left;
		z-index:1;
	}

	.about .copy-block h1 {
		font-size:18vw;
		line-height: 14vw;   
		letter-spacing: 0.025em;
	}
	
	.about .copy-block h2 {
	  margin-top:1vw;
	}
	
	.about .copy-block p {
		font-size:3.7vw;
		line-height:5.8vw; 
		margin-top:5vw;
	}
	
	.about .title-icon{
	  width:14vw;
	  margin-bottom:1vw;
	}
	
	.about .media {
		margin-top:5vw;
		width:65vw;
		margin-right:0;
	}
	
	.about .bg {
	  width:100%;
	  height: 100%;
	  object-fit: cover;
	  position: absolute;
	  left:0;
	  top:0;
	  z-index:0;
	}

}


.roundSmall,
.roundMedium,
.roundLarge {
}
.roundMedium {
		width: 4px;
		height: 4px;
}
.roundLarge {
		width: 5px;
		height: 5px;
}
.starSmall,
.starMedium,
.starLarge {
		position: absolute;
    z-index:100;
		width: 10px;
		height: 10px;
		background-image: url(../assets/heart5.png);
	  background-size: 100% 100%;
}
.starMedium {
		width: 15px;
		height: 15px;
}
.starLarge {
		width: 20px;
		height: 20px;
}
.realSmall,
.realMedium,
.realLarge {
		position: absolute;
    z-index:100;
		width: 25px;
		height: 25px;
		background-image: url(../assets/heart1.png);
	background-size: 100% 100%;
}
.realMedium {
		width: 30px;
		height: 30px;
}
.realLarge {
		width: 40px;
		height: 40px;
}
.sharpSmall,
.sharpMedium,
.sharpLarge {
		position: absolute;
    z-index:100;
		width: 20px;
		height: 20px;
		background-size: 100% 100%;
		background-image: url(../assets/heart2.png);
	background-size: 100% 100%;
}
.sharpMedium {
		width: 28px;
		height: 28px;
}
.sharpLarge {
		width: 35px;
		height: 35px;
}
.ringSmall,
.ringMedium,
.ringLarge {
		position: absolute;
    z-index:100;
		width: 15px;
		height: 15px;
		background-image: url(../assets/heart3.png);
	  background-size: 100% 100%;
}
.ringMedium {
		width: 25px;
		height: 25px;
}
.ringLarge {
		width: 35px;
		height: 35px;
}
.header {
  background:white;
  position: fixed;
  z-index: 100;
  top:0;
  left:0;
  width:100%;
}


.header .header-brands {
  position: absolute;
  left:1.5vw;
  top:.8vw;
  display: flex;
  gap:1.5vw;
  flex-direction: row;
  justify-content: center;
  vertical-align: middle;
}

.header .header-brands .ctqLogo{
  width:4.9vw;
  max-width:94px;
  min-width:47px;
  padding-top:.3vw;
}

.header .header-brands .ctqLogo:hover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}


.header .header-brands .lolLogo{
  width:4.9vw;
  max-width:94px;
  min-width:47px;
  padding-top:0vw;
}

.header .header-brands .lolLogo:hover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}


.header .header-nav {
  position: absolute;
  right:1.5vw;
  top:2.6vw;
  display: flex;
  gap:20px;
  flex-direction: row;
}

.header .header-nav img:hover {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

.header .header-nav img{
  height:2.5vw;
  max-height:46px;
  min-height:23px;
}


@media (orientation: portrait) {
  .header{
    top:2vw;
  }
  .header .header-brands {
    margin-left:3vw;
  }

  .header .header-brands .lolLogo{
    margin-left:3vw;
  }

  .header .header-nav {
    position: absolute;
    right:3vw;
    top:2.6vw;
    display: flex;
    gap:0;
    flex-direction: row;
  }

  .header .header-nav img{
    margin-left:3vw;
  }

}
.scroll-icon {
  position: fixed;
  width:4vw;
  bottom:1vw;
  left:1.5vw;
  z-index:100;
  pointer-events: none;
}

.scroll-icon-text{
  position: relative;
  top:0;
  left:0;
  width:4vw;

  min-width: 40px;
  max-width: 77px;
}

.scroll-icon-arrows{
  position: absolute;
  top:0;
  left:0;
  width:4vw;
  min-width: 40px;
  max-width: 77px;
}


@media (orientation: portrait) {
  .scroll-icon {
    bottom:3vw;
    left:4vw;
    
  }
}
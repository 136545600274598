.before {
  min-height: 100%;
  background:transparent;
  position: relative;
  z-index: 11;
  overflow:hidden;
}

.before .copy-block {
  width:42vw;
  left:50%;
  top:50%;
  transform: translate(-50%,-50%);
  position: absolute;
  text-align: center;
}

.before h1 {
  font-size:4.8vw;
}

.before .copy-block h2 {
  margin-top:1vw;
}

.before .copy-block p {
  margin-top:3vw;
}

.before .media {
  width:100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left:0;
  top:0;
}


@media (orientation: portrait) {


  .before .copy-block {
    width:80vw;
    left:50%;
    top:50%;
    transform: translate(-50%,-50%);
    position: absolute;
    text-align: center;
  }
  
  .before h1 {
    font-size:9vw;
  }
  
  .before .copy-block h2 {
    margin-top:1vw;
  }
  
  .before .copy-block p {
    margin-top:3vw;
    font-size:3.7vw;
  }
  
  .before .media {
    object-position: 90%;
  }
  

}